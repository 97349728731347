import React, { useCallback, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import {
    AlvaraDeObra,
    IProperty,
} from "../../../../../shared/interfaces/IProperties";
import { PropertiesApiService } from "../../../../../shared/services/api/properties-api.service";
import { ApiErrorHandler } from "../../../../../shared/utils/errors.utils";
import { measureMask } from "../../../../../shared/utils/mask.utils";

interface Props {
    subscription?: string;
}

export const Alvara: React.FC<Props> = ({ subscription }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [fetchedAlvara, setFetchedAlvara] = useState<AlvaraDeObra>();

    const fetchAlvaraInfo = useCallback(async (subscription_: string) => {
        try {
            setLoading(true);
            const alvara = await PropertiesApiService.getPropertyAlvara(
                subscription_
            );

            setFetchedAlvara(alvara);
        } catch (err) {
            ApiErrorHandler(err, "warn");
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (subscription && subscription.length > 0) {
            fetchAlvaraInfo(subscription);
        }
    }, [fetchAlvaraInfo, subscription]);

    console.log(fetchedAlvara);

    return (
        <>
            <Row>
                <h4>Informações Alvará de Obras</h4>
            </Row>
            {!loading && (
                <div>
                    <Col>
                        <Form.Label>Número</Form.Label>
                        <Form.Control disabled value={fetchedAlvara?.numero} />
                    </Col>

                    <Col>
                        <Form.Label>Ano</Form.Label>
                        <Form.Control disabled value={fetchedAlvara?.ano} />
                    </Col>

                    <Col>
                        <Form.Label>Área</Form.Label>
                        <Form.Control disabled value={fetchedAlvara?.area} />
                    </Col>

                    <Col>
                        <Form.Label>Tipo</Form.Label>
                        <Form.Control disabled value={fetchedAlvara?.tipo} />
                    </Col>
                    <Col>
                        <Form.Label>Processo adm</Form.Label>
                        <Form.Control
                            disabled
                            value={fetchedAlvara?.processoadministrativo}
                        />
                    </Col>
                </div>
            )}
        </>
    );
};
