import { IEnv } from "../../../shared/interfaces/IEnv";

export default {
    client: {
        name: "arapongas",
        nomePrefeitura: "Prefeitura Municipal de Arapongas",
        logo: "https://d1cge5fflrvk0g.cloudfront.net/arapongas/logos/logo.png",
        brasao: "https://d1cge5fflrvk0g.cloudfront.net/arapongas/logos/logo.png",
        municipio: "| ARAPONGAS - PR",
    },
    webgeo: {
        coordinatesPattern: "EPSG:32722",
        bci: {
            nomeSecretaria: "",
        },
        confrontante: {
            informacoesRodape: "",
            nomeSecretaria: "",
        },
        consultaPrevia: {
            nomeSecretaria: "",
            contact: {
                email: "xxx",
                endereco: "xxx",
                horarioAtendimento: "xxx",
                telefone: "xxx",
            },
            leiUsoDeSolo: "",
            leiVagasDeEstacionamento: "",
        },
        config: {
            bci: false,
            confrontante: false,
            consultaPrevia: false,
            iptu: false,
            laws: false,
        },
        map: {
            center: [-51.436111472996444, -23.408408957288422],
            zoom: 18,
        },
    },
    api: {
        url: "http://localhost:3330",
    },
    geoserver: {
        url: "https://geoserversp.semv.com.br/geoserver",
    },
} as IEnv;
