import React from "react";

import { Row, Col } from "react-bootstrap";

import {
    IProperty,
    IPropertyTestada,
} from "../../../../shared/interfaces/IProperties";
import { measureMask } from "../../../../shared/utils/mask.utils";
import { Divisor, DotLine, Topic } from "../styles";

interface TestadaProps {
    testadas: IPropertyTestada[];
    property: IProperty;
}

export const TestadaJacarezinho: React.FC<TestadaProps> = ({
    testadas,
    property,
}) => {
    return (
        <>
            <Topic>
                <h5>Metragens</h5>
            </Topic>
            <div>
                <Row>
                    <Col>
                        <p>Área Terreno: {measureMask(property.areaterreno)}</p>
                    </Col>
                    <Col>
                        <p>
                            Profundidade:{" "}
                            {property.profundidade?.trim()
                                ? `${(+property.profundidade).toFixed(2)} m`
                                : property.profundidade}
                        </p>
                    </Col>
                    <Col>
                        <p>
                            Área const. Unidade:{" "}
                            {measureMask(property.areaconstruida)}
                        </p>
                    </Col>
                    <Col>
                        <p>
                            Área total const.:{" "}
                            {measureMask(property.areatotalconstruida)}
                        </p>
                    </Col>
                </Row>
            </div>
            <DotLine />
            {testadas.map((testada, index) => (
                <div key={testada.id}>
                    {index > 0 && <DotLine />}
                    <Row>
                        <Col>
                            <p>Testada</p>
                        </Col>
                        <Col>
                            <p>Metragem</p>
                        </Col>
                        <Col>
                            <p>Seção</p>
                        </Col>
                        <Col>
                            <p>Logradouro</p>
                        </Col>
                        <Col>
                            <p>Bairro</p>
                        </Col>
                        <Col>
                            <p>Valor seção</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Principal</p>
                        </Col>
                        <Col>
                            <p>{testada.metragem}</p>
                        </Col>
                        <Col>
                            <p>{testada.codigosecao}</p>
                        </Col>
                        <Col>
                            <p>{testada.logradouro}</p>
                        </Col>
                        <Col>
                            <p>{testada.bairro}</p>
                        </Col>
                        <Col>
                            <p>
                                {parseFloat(
                                    testada.valorsecao || "0"
                                ).toLocaleString("pt-BR", {
                                    minimumFractionDigits: 2,
                                })}
                            </p>
                        </Col>
                    </Row>
                </div>
            ))}
        </>
    );
};
